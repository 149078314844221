var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      _vm._l(_vm.formDataF["portCalls"], function(portCall) {
                        return _c(
                          "asyent-card",
                          {
                            key: portCall.index,
                            attrs: { title: "PortCalls" }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          portCall["locationCode"],
                                          portCall["locationName"]
                                        ],
                                        label: "Location"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: portCall["voyageNumber"],
                                        label: "Voyage Number"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: portCall["portStatus"],
                                        label: "Port Status"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: portCall["transportPhase"],
                                        label: "Transport Phase"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          portCall["vehicleId"],
                                          portCall["vehicleName"]
                                        ],
                                        label: "Vehicle ID & Name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: portCall["actualTimeOfArrival"],
                                        label: "actualTimeOfArrival"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          portCall["actualTimeOfDeparture"],
                                        label: "actualTimeOfDeparture"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          portCall["estimatedTimeOfArrival"],
                                        label: "estimatedTimeOfArrival"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          portCall["estimatedTimeOfDeparture"],
                                        label: "estimatedTimeOfDeparture"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          _vm.formDataF["idCargoVoyage"][
                                            "departurePort"
                                          ]["code"],
                                          _vm.formDataF["idCargoVoyage"][
                                            "departurePort"
                                          ]["name"],
                                          _vm.formDataF["idCargoVoyage"][
                                            "departurePort"
                                          ]["countryName"]
                                        ],
                                        label: "Departure Port"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          _vm.formDataF["idCargoVoyage"][
                                            "voyageNumber"
                                          ],
                                        label: "Voyage Number"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          _vm.formDataF["idCargoVoyage"][
                                            "departureDate"
                                          ],
                                        "as-date": "",
                                        label: "Departure Date"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          _vm.formDataF["transportMeans"][
                                            "idNumber"
                                          ],
                                          _vm.formDataF["transportMeans"][
                                            "name"
                                          ]
                                        ],
                                        label: "Transport Means"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["carrier"]["id"],
                                        label: "SCAC Id."
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["carrier"]["name"],
                                        label: "Name & Address"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }